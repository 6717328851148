.container {
  display: flex;
  flex-direction: column;
}

.table-header-row {
  padding-bottom: var(--var-spacing-lg);
  display: flex;
  flex-direction: row;
}

.table-create-button {
  margin-left: auto;
}
