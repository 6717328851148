.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.card {
  width: 70%;
  max-width: 1000px;
  height: 100%;
}

.header {
  margin: var(--var-spacing-md);
}

.title-row {
  display: flex;
  flex-direction: row;
}

.header-tooltip {
  max-width: 400px;
}

.info-icon {
  margin: var(--var-spacing-sm);
  font-size: var(--var-font-size-md);
  color: var(--var-fg-gray-1);
}

.form {
  margin-top: var(--var-spacing-xl);
}

.submit-button {
  margin-left: var(--var-spacing-xl);
}
