.container {
  width: 100%;
  height: 100%;
  margin: -12px; // react-grid-layout margin
}

.main-card {
  width: 100%;
}

.tile-card {
  flex: 1 1 auto;
}
