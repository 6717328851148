.cell {
  width: 100%;
  height: 100%;
}

.display-row {
  display: flex;
  flex-direction: row;
}

.main-text {
  margin-right: 4px;
}

.popover {
  max-width: 300px;
  width: auto;
}
