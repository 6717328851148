.container {
  width: 100%;
  height: calc(100vh - 280px);
  position: relative;
}

.map {
  width: 100%;
  height: 100%;
}

.float-control {
  position: absolute;
  top: 10px;
  right: 70px;
}

.control {
  position: absolute;
  top: 60px;
  right: 20px;
  width: 400px;
}

.control-inner {
  display: grid;
  grid-template-columns: 100px 1fr min-content;
  gap: 10px;
  margin-bottom: var(--var-spacing-lg);
}

.float-import {
  position: absolute;
  top: 10px;
  right: 120px;
}

.import {
  position: absolute;
  top: 60px;
  right: 40px;
  width: 400px;
}

.import-inner {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
