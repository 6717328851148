.container {
  width: 100%;
}

.row {
  width: 100%;
  margin: var(--var-spacing-xl);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}
