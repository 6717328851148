body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  font-size: 62.5%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  --var-spacing-xs: 4px;
  --var-spacing-sm: 8px;
  --var-spacing-md: 14px;
  --var-spacing-lg: 20px;
  --var-spacing-xl: 32px;
  --var-font-size-xs: 0.8rem;
  --var-font-size-sm: 1.2rem;
  --var-font-size-md: 1.6rem;
  --var-font-size-lg: 2rem;
  --var-font-size-xl: 2.4rem;
  --var-animation-transition: 300ms;
  --var-bg-gray-1: #fafafa;
  --var-bg-gray-2: #f5f5f5;
  --var-bg-gray-3: #f0f0f0;
  --var-bg-gray-4: #d9d9d9;
  --var-bg-gray-5: #bfbfbf;
  --var-fg-gray-1: #8c8c8c;
  --var-fg-gray-2: #595959;
  --var-fg-gray-3: #434343;
  --var-fg-gray-4: #262626;
  --var-fg-gray-5: #1f1f1f;
}
