.login {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.background {
  position: relative;
  flex: 1 1 auto;
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: white;
}

.above-img {
  position: absolute;
  left: 100px;
  right: 100px;
  bottom: 100px;
  z-index: 999;
}

.logo {
  display: block;
  width: 400px;
  color: white;
  margin-bottom: 30px;
}

.form-container {
  flex: 0 0 500px;
  z-index: 999;
  padding: 0 var(--var-spacing-md);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.segmented {
  margin: var(--var-spacing-lg) auto;
}
