.header {
  display: flex;
  height: 100%;
  align-items: center;
}

.trigger {
  padding: var(--var-spacing-md);
  font-size: var(--var-font-size-lg);
  cursor: pointer;
  transition: all var(--var-animation-transition);
  color: #fff;

  :hover {
    color: #1890ff;
  }
}
