.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.form {
  width: 70%;
  max-width: 1000px;
}
