.cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.text-main {
  font-size: var(--var-font-size-xl);
}
