.card {
  width: 100%;
  height: 100%;
}

.inner-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.section {
  margin: var(--var-spacing-lg) 0;
}
