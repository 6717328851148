.sider-container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.logo-full {
  padding: var(--var-spacing-xl);
  color: #fff;
}

.logo {
  padding: var(--var-spacing-lg);
  color: #fff;
}

.avatar {
  display: inline;
  margin: var(--var-spacing-md) 0;
  align-self: center;
  background-color: #444;
}

.footer {
  margin-top: auto;
}
