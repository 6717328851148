.container {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  padding: var(--var-spacing-lg);
}

.header {
  margin-bottom: var(--var-spacing-lg);
}

.content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  flex: 1 1 auto;
}
