.container {
  padding: var(--var-spacing-md) var(--var-spacing-lg);
  display: flex;
  flex-direction: column;
}

.chart-container {
  margin-bottom: 40px; /* Adjust the value as needed */
}

.title {
  margin: var(--var-spacing-lg);
}
