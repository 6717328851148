.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chart-container {
  flex: 1 1 auto;
}

.pie-charts-container {
  display: flex;
  align-items: center;
}

.segmented {
  margin-left: auto;
  margin-bottom: var(--var-spacing-lg);
}
